export const Main = () => {
    const form = document.querySelector('.wpcf7-form');
    if (!form) return;

    // Get page title and set it to the hidden input field
    const pageTitle = document.title;
    form.querySelector('[name="page"]').value = pageTitle;

    //
    const downloadTitle = document.querySelector('.calendar-download .download-link');
    // remove () ans anything inside it and trim the string
    const downloadTitleText = downloadTitle.textContent.replace(/\(.*\)/, '').trim();
    form.querySelector('[name="download"]').value = downloadTitleText;
}

document.addEventListener('DOMContentLoaded', () => {
    Main()
})